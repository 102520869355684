import React, { useContext, useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

import { AuthContext, checkTokenStatus } from '../../App';
import CreateCommentCard from '../cards/CreateCommentCard';
import AddEvaluationModal from '../modals/AddEvaluationModal';
import AddForecastLinkModal from '../modals/AddForecastLinkModal';
import ConfirmationModal from '../modals/ConfirmationModal';
import ModerationModal from '../modals/ModerationModal';
import SetResolutionModal from '../modals/SetResolutionModal';
import StructuredQuestionForm from '../other/StructuredQuestionForm/StructuredQuestionForm';

import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteQuestion,
  fetchForecastsByOutcome,
  fetchOutcomes,
  selectCurrentCrowdForecast,
  selectCurrentUserForecastByQuestion,
  selectEvaluationByQuestionUser,
  selectForecastsByQuestion,
  selectOutcomeById,
  selectRelevanceEvaluationsByQuestionUser,
  updateQuestion
} from '../../store/slices/outcomeSlice';
import { selectSettingByName } from '../../store/slices/settingsSlice';

import TextField from '@mui/material/TextField';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { parseISO } from 'date-fns';
import ukLocale from 'date-fns/locale/en-GB';

import { BiDuplicate } from 'react-icons/bi';
import { FaAsterisk, FaChartArea, FaSpellCheck } from 'react-icons/fa';
import {
  FiCheck,
  FiDelete,
  FiEdit,
  FiLink,
  FiStar,
  FiTarget
} from 'react-icons/fi';
import { GoComment } from 'react-icons/go';
import { MdSpeed } from 'react-icons/md';
import { PiTarget, PiTargetLight, PiTargetThin } from 'react-icons/pi';
import { TbLayoutAlignMiddle } from 'react-icons/tb';

import {
  PieChart,
  ShowChart,
  SsidChartOutlined,
  StackedLineChart
} from '@mui/icons-material';
import {
  Button,
  Card,
  Divider,
  FormControlLabel,
  Link,
  Switch,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';
import MDEditor from '@uiw/react-md-editor';
import { BsCheck2All, BsPlusSlashMinus } from 'react-icons/bs';
import { ClipLoader } from 'react-spinners';
import { addAIForecast } from '../../store/slices/autoSlice';
import RoleBadgeIcon from '../icons/RoleBadgeIcon';
import ErrorModal from '../modals/ErrorModal';
import SetRelevanceEvaluationModal from '../modals/SetRelevanceEvaluationModal';
import SetResolutionImpactModal from '../modals/SetResolutionImpactModal';
import SuccessModal from '../modals/SuccessModal';
import AdminForecastView from '../other/AdminForecastView';
import ForecastTimeline from '../other/charts/ForecastTimeline';
import InfoTooltip from '../other/InfoTooltip';
import Markdown from '../other/Markdown';

export default function QuestionCard({
  question,
  isLoggedIn,
  isVotingDisabled,
  setIsVotingDisabled,
  detail
}) {
  const dispatch = useDispatch();
  const { setIsLoggedIn, userData } = useContext(AuthContext);
  const userId = userData.id;
  const navigate = useNavigate();
  const theme = useTheme();
  const usernameSettings = useSelector((state) =>
    selectSettingByName(state, 'View Usernames')
  );
  const resolutionSettings = useSelector((state) =>
    selectSettingByName(state, 'Resolution Impact Decider')
  );

  const maxQuestionLength = 2000;
  const maxResolutionCriteriaLength = 2000;

  const questionId = question.id;
  const forecasts = useSelector((state) =>
    selectForecastsByQuestion(state, questionId)
  );
  const usersData = useSelector((state) => state.users.entities);
  const [questionText, setQuestionText] = useState(null);
  const [updateQuestionRequestStatus, setUpdateQuestionRequestStatus] =
    useState('idle');
  const [deleteQuestionRequestStatus, setDeleteQuestionRequestStatus] =
    useState('idle');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showAddComment, setShowAddComment] = useState(false);
  const [showAddForecastLink, setShowAddForecastLink] = useState(false);
  const [showAddEvaluation, setShowAddEvaluation] = useState(false);
  const [showModerationModal, setShowModerationModal] = useState(false);
  const [showResolutionImpactModal, setShowResolutionImpactModal] =
    useState(false);
  const [showResolutionModal, setShowResolutionModal] = useState(false);
  const [resolutionImpactSubmitter, setResolutionImpactSubmitter] =
    useState(null);
  const [showRelevanceEvaluationModal, setShowRelevanceEvaluationModal] =
    useState(false);
  const [editCard, setEditCard] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const evaluation = useSelector((state) =>
    selectEvaluationByQuestionUser(state, questionId, userId)
  );

  const outcome = useSelector((state) =>
    selectOutcomeById(state, question.outcome_id)
  );
  const outcomeStatus = useSelector(
    (_state) => _state.outcomes.outcomes.status
  );
  const [resolutionCriteria, setResolutionCriteria] = useState(null);
  const [source, setSource] = useState(null);
  const [extraInfo, setExtraInfo] = useState(null);
  const [resolutionDate, setResolutionDate] = useState(null);
  const [outcomeAsQuestion, setOutcomeAsQuestion] = useState(null);
  const hasResolved = question.current_resolution === undefined ? false : true;
  const [saveClicked, setSaveClicked] = useState(false);
  const isDarkMode = localStorage.getItem('mode') === 'dark';
  const [isAIForecastButtonDisabled, setIsAIForecastButtonDisabled] =
    useState(false);

  const forecastStatus = useSelector((state) => {
    if (Object.keys(state.outcomes.outcomes.entities).length > 0) {
      return state.outcomes.outcomes.entities[question.outcome_id]
        .forecastStatus;
    } else return 'undefined';
  });

  useEffect(() => {
    let isMounted = true;
    function fetchQuestionsData() {
      if (isMounted) {
        if (forecastStatus === 'idle') {
          dispatch(fetchForecastsByOutcome(question.outcome_id));
        }
      }
    }

    fetchQuestionsData();
    return () => {
      isMounted = false;
    };
  }, [forecastStatus, dispatch, question]);

  let resolution_text = null;
  if (question.current_resolution != null) {
    resolution_text =
      'Resolved ' +
      moment.utc(question.current_resolution.resolved_at).local().fromNow();
  }

  var isStructured = false;
  if (question) {
    if (question.hasOwnProperty('event')) {
      isStructured = true;
    }
  }
  const forecastOverruleSettings = useSelector((state) =>
    selectSettingByName(state, 'Forecast Submission Override')
  );

  const currentUserForecast = useSelector((state) =>
    selectCurrentUserForecastByQuestion(state, {
      userId: userData.id,
      questionId: questionId
    })
  );
  const currentUserOutcomeForecast = useSelector((state) =>
    selectCurrentUserForecastByQuestion(state, {
      userId: userData.id,
      questionId: outcome?.outcome_question_id
    })
  );
  const aiForecastSetting = useSelector((state) =>
    selectSettingByName(state, 'AI Forecast Toggle')
  );

  useEffect(() => {
    // check if auth token is valid
    if (checkTokenStatus() === false) {
      setIsLoggedIn(false);
      setIsVotingDisabled(true);
      return <Navigate to={'/login'} />;
    } else {
      setIsLoggedIn(true);
    }
  }, [setIsLoggedIn, setIsVotingDisabled]);

  useEffect(() => {
    let isMounted = true;
    setErrorMessage('');
    function fetchOutcomeData() {
      if (isMounted) {
        if (outcomeStatus === 'idle') {
          const token = localStorage.getItem('auth_token');
          dispatch(fetchOutcomes({ auth_token: token }));
        }
      }
    }
    fetchOutcomeData();
    return () => {
      isMounted = false;
    };
  }, [outcomeStatus, dispatch, outcome]);

  useEffect(() => {
    checkButtonStatus();
  }, [outcome, question]);

  const openQuestionView = (id) => {
    navigate(`/questions/${id}`);
  };

  const createComment = () => {
    setShowAddComment(true);
  };

  const changeQuestionText = (event) => {
    setQuestionText(event.target.value);
  };

  const changeOutcomeAsQuestion = (event) => {
    setOutcomeAsQuestion(event.target.checked);
  };

  const changeSource = (event) => setSource(event.target.value);
  const changeResolutionCriteria = (value) => setResolutionCriteria(value);
  const changeExtraInfo = (event) => setExtraInfo(event.target.value);

  const clickAddEvaluation = () => {
    setShowAddEvaluation(true);
  };

  const replyClick = () => {
    createComment();
  };

  const relevanceEvaluations = useSelector((state) =>
    selectRelevanceEvaluationsByQuestionUser(state, questionId, userId)
  );

  const currentRelevanceEvaluation = relevanceEvaluations
    ? relevanceEvaluations.find((evaluation) => evaluation.status === 'current')
    : null;

  useEffect(() => {
    if (question.question_text) {
      setQuestionText(question.question_text);
    }
  }, [question.question_text]);

  useEffect(() => {
    if (question.outcome_as_question) {
      setOutcomeAsQuestion(question.outcome_as_question);
    }
  }, [question.outcome_as_question]);

  useEffect(() => {
    if (question.resolution_criteria) {
      setResolutionCriteria(question.resolution_criteria);
    }
  }, [question.resolution_criteria]);

  useEffect(() => {
    if (question.source) {
      setSource(question.source);
    }
  }, [question.source]);

  useEffect(() => {
    if (question.relevance_reason) {
      setExtraInfo(question.relevance_reason);
    }
  }, [question.relevance_reason]);

  useEffect(() => {
    if (question.resolution_date) {
      setResolutionDate(parseISO(question.resolution_date));
    }
  }, [question.resolution_date]);

  const canUpdateQuestion =
    [
      question.id,
      questionText ||
        resolutionCriteria ||
        source ||
        resolutionDate ||
        extraInfo ||
        outcomeAsQuestion !== null,
      checkTokenStatus()
    ].every(Boolean) && updateQuestionRequestStatus === 'idle';

  const updateQuestionData = async () => {
    if (canUpdateQuestion) {
      let isMounted = true;
      setErrorMessage('');
      try {
        setUpdateQuestionRequestStatus('pending');
        const token = localStorage.getItem('auth_token');
        let payload = {
          id: question.id,
          auth_token: token
        };
        if (questionText) {
          payload.question_text = questionText;
        }
        if (resolutionCriteria) {
          payload.resolution_criteria = resolutionCriteria;
        }
        if (source) {
          payload.source = source;
        }
        if (resolutionDate) {
          payload.resolution_date = resolutionDate;
        }
        if (extraInfo) {
          payload.relevance_reason = extraInfo;
        }
        if (outcomeAsQuestion !== null) {
          payload.outcome_as_question = outcomeAsQuestion;
        }
        if (
          question.status === 'Rejected' &&
          question.created_by.username === userData.username
        ) {
          payload.reset_pending = true;
        }

        await dispatch(updateQuestion(payload))
          .unwrap()
          .then((response) => {
            if (isMounted) {
              setEditCard(!editCard);
            }
          });
      } catch (err) {
        setErrorMessage(`Failed to update question: ${err.message}`);
      } finally {
        if (isMounted) {
          setUpdateQuestionRequestStatus('idle');
          isMounted = false;
        }
      }
    } else if (checkTokenStatus() === false) {
      setIsLoggedIn(false);
      return <Navigate to={'/login'} />;
    } else {
      setErrorMessage('Question could not be updated.');
    }
  };

  const canDeleteQuestion =
    [question.id, checkTokenStatus()].every(Boolean) &&
    deleteQuestionRequestStatus === 'idle';
  const deleteQuestionData = async () => {
    if (canDeleteQuestion) {
      let isMounted = true;
      setErrorMessage('');
      try {
        setDeleteQuestionRequestStatus('pending');
        const token = localStorage.getItem('auth_token');
        let payload = {
          id: question.id,
          auth_token: token
        };
        await dispatch(deleteQuestion(payload))
          .unwrap()
          .then((response) => {
            if (isMounted) {
              if (editCard) {
                setEditCard(!editCard);
              }
            }
          });
      } catch (err) {
        setErrorMessage(`Failed to delete question: ${err.message}`);
      } finally {
        if (isMounted) {
          setDeleteQuestionRequestStatus('idle');
          isMounted = false;
          return <Navigate to={'/outcomes'} />;
        }
      }
    } else if (checkTokenStatus() === false) {
      setIsLoggedIn(false);
      return <Navigate to={'/login'} />;
    } else {
      setErrorMessage('Question could not be deleted.');
    }
  };

  const timeSincePosted = () => {
    return moment.utc(question.created_at).local().fromNow();
  };

  const daysSinceOutcomeForecastingStarted = () => {
    // if forecasting hasn't started yet, returns null
    if (outcome) {
      if (!outcome.statuses.includes('Forecasting')) {
        return null;
      } else {
        return moment().diff(
          moment.utc(outcome.forecasting_start_date).local(),
          'days'
        );
      }
    }
  };

  const isEdited = () => {
    return question.modified_at ? true : false;
  };

  const getStatusColour = () => {
    let status =
      question.status !== 'Completed' ? question.status : completedStatusCalc();
    switch (status) {
      case 'Pending':
        return theme.palette.statuses.mid1;
      case 'Rejected':
        return theme.palette.statuses.mid5;
      case 'Not Submitted':
        return theme.palette.statuses.mid2;
      case 'Accepted':
        return theme.palette.statuses.mid3;
      case 'Submitted':
        return theme.palette.statuses.mid3;
      case 'Closed':
        return theme.palette.statuses.mid4;
      case 'Duplicate':
        return theme.palette.statuses.mid6;
      default:
        return theme.palette.statuses.mid7;
    }
  };

  const getResolutionColour = () => {
    switch (question.current_resolution.name) {
      case 'True':
        return theme.palette.statuses.mid1;
      case 'False':
        return theme.palette.statuses.mid5;
      case 'Ambiguous':
        return theme.palette.statuses.mid4;
      case 'Not Set':
        return theme.palette.success.main;
      default:
        return null;
    }
  };

  const completedStatusCalc = () => {
    if (outcome !== undefined) {
      if (outcome.statuses.includes('Forecasting')) {
        return 'Submitted';
      } else {
        return 'Closed';
      }
    } else {
      return 'Closed';
    }
  };

  const categoryTextColor = (category) => {
    switch (category) {
      case 'Very Low':
        return 'text-red-500';
      case 'Low':
        return 'text-orange-500';
      case 'Medium':
        return 'text-blue-500';
      case 'High':
        return 'text-teal-500';
      case 'Very High':
        return 'text-green-500';
      default:
        return 'bg-blue-500';
    }
  };

  const evaluationScoreCategoryTextColor = (category) => {
    switch (category) {
      case 'Furthest':
        return 'text-red-500';
      case 'Far':
        return 'text-orange-500';
      case 'Average':
        return 'text-blue-500';
      case 'Close':
        return 'text-teal-500';
      case 'Correct':
        return 'text-green-500';
      default:
        return 'bg-blue-500';
    }
  };

  const minutesSinceOutcomeAIForecastButtonPressed = () => {
    if (outcome?.ai_forecast_button_pressed) {
      return moment().diff(
        moment(outcome?.ai_forecast_button_pressed, 'YYYY-MM-DDThh:mm:ss'),
        'minutes'
      );
    } else {
      return 999999999;
    }
  };

  const minutesSinceQuestionAIForecastButtonPressed = () => {
    if (question?.ai_forecast_button_pressed) {
      return moment().diff(
        moment(question?.ai_forecast_button_pressed, 'YYYY-MM-DDThh:mm:ss'),
        'minutes'
      );
    } else {
      return 999999999;
    }
  };

  const checkButtonStatus = async () => {
    try {
      if (
        minutesSinceOutcomeAIForecastButtonPressed() >= 10 &&
        minutesSinceQuestionAIForecastButtonPressed() >= 10
      ) {
        setIsAIForecastButtonDisabled(false);
      } else {
        setIsAIForecastButtonDisabled(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const createAIForecast = async (e) => {
    e.stopPropagation();
    try {
      const token = localStorage.getItem('auth_token');
      let payload = { question_id: question.id, auth_token: token };
      setIsAIForecastButtonDisabled(true);
      await dispatch(addAIForecast(payload))
        .unwrap()
        .then((response) => {
          if (response.status === 'success') {
            setSuccessMessage('AI Forecast Initiated.');
            setShowSuccess(true);
          } else {
            setErrorMessage(
              `Please wait ${response.data.waitTime} minutes before trying again.`
            );
            setShowError(true);
          }
        });
    } catch (error) {
      setErrorMessage(`Failed to add AI forecasts: ${error.message}`);
      setShowError(true);
    }
  };

  let content;
  let canUserEvaluate =
    outcomeStatus === 'succeeded'
      ? !isVotingDisabled &&
        !evaluation &&
        outcome.statuses.includes('Evaluation') &&
        question.created_by.username !== userData.username
      : false;
  let canRenderCard = outcomeStatus === 'succeeded' && question && outcome;

  const showAdminForecasts =
    outcome &&
    (outcome.statuses.includes('Forecasting') ||
      outcome.statuses.includes('Closed')) &&
    (userData.role === 'Admin' || userData.role === 'Moderator');

  if (usernameSettings === undefined) {
    content = null;
  } else if (outcomeStatus === 'loading') {
    content = (
      <div className="text-center">
        <ClipLoader color="#f87171" loading={true} size={100} />
      </div>
    );
  } else if (canRenderCard) {
    content = (
      <div>
        <Card
          id={'questionCard' + questionId}
          sx={{
            borderLeftWidth: '5px',
            borderLeftColor: getStatusColour()
          }}
          className="QuestionCard rounded shadow-lg mx-10 my-2">
          <div className="px-5 pt-3">
            {editCard &&
            (userData.username === question.created_by.username ||
              userData.role === 'Admin') ? (
              isStructured ? (
                <>
                  {errorMessage && (
                    <Typography color="error">{errorMessage}</Typography>
                  )}
                  <StructuredQuestionForm
                    outcome={outcome}
                    setErrorMessage={setErrorMessage}
                    question={question}
                    setEditCard={setEditCard}
                    saveClicked={saveClicked}
                    setSaveClicked={setSaveClicked}
                    outcomeAsQuestion={outcomeAsQuestion}
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={outcomeAsQuestion}
                        onChange={changeOutcomeAsQuestion}
                      />
                    }
                    label="Outcome as a Question"
                  />
                </>
              ) : (
                <>
                  {errorMessage && (
                    <Typography color="error">{errorMessage}</Typography>
                  )}
                  <div className="flex items-center">
                    <Typography className="font-semibold flex items-center my-1">
                      Question Detail
                    </Typography>
                    <div className="flex items-center">
                      {question.current_resolution == null && (
                        <Typography
                          sx={{
                            borderColor: getStatusColour(),
                            backgroundColor: getStatusColour(),
                            py: 0,
                            px: 0.3,
                            fontSize: '0.85rem',
                            mx: 0.6
                          }}
                          className={`border-2 rounded text-white font-normal text-xs px-1 ml-1`}>
                          {question.status !== 'Completed'
                            ? question.status
                            : completedStatusCalc()}
                        </Typography>
                      )}
                      {hasResolved && (
                        <Typography
                          sx={{
                            borderColor: getResolutionColour(),
                            backgroundColor: getResolutionColour(),
                            py: 0,
                            px: 0.3,
                            fontSize: '0.85rem',
                            mx: 0.6,
                            textTransform: 'capitalize'
                          }}
                          className={`border-2 rounded text-white font-normal text-xs px-1 ml-1`}>
                          {question.current_resolution?.name.replace(
                            'Not Set',
                            'Reopened'
                          )}
                        </Typography>
                      )}
                    </div>
                  </div>

                  <TextField
                    type="text"
                    variant="outlined"
                    multiline
                    minRows={2}
                    value={questionText}
                    sx={{ my: 0.6 }}
                    inputProps={{ style: { fontSize: '0.875rem' } }}
                    onChange={(event) => changeQuestionText(event)}
                    helperText={`${questionText.length}/${maxQuestionLength}`}
                    error={questionText.length > maxQuestionLength}
                    className="w-full"
                  />
                  <div className="flex flex-wrap items-center py-2">
                    <div className="flex-col w-2/6 pr-2">
                      <Typography>Resolution Criteria</Typography>
                      <div data-color-mode={isDarkMode ? 'dark' : 'light'}>
                        <MDEditor
                          id="resolution-criteria"
                          value={resolutionCriteria}
                          className="w-full"
                          onChange={(event) => changeResolutionCriteria(event)}
                          textareaProps={{
                            placeholder:
                              'i.e. the process by which it will be determined whether the outcome described occured.',
                            maxLength: maxResolutionCriteriaLength,
                            required: true,
                            autoCorrect: 'on'
                          }}
                          preview="edit"
                          style={{
                            backgroundColor: isDarkMode
                              ? theme.palette.background.paper
                              : '#fff',
                            color: isDarkMode
                              ? theme.palette.text.primary
                              : '#000'
                          }}
                          defaultTabEnable={true}
                        />
                      </div>
                    </div>
                    <div className="flex-col w-1/6 pr-2 mx-4">
                      <Typography>Source Link</Typography>
                      <TextField
                        type="text"
                        variant="outlined"
                        value={source}
                        onChange={(event) => changeSource(event)}
                        className="w-full"></TextField>
                    </div>
                    <div className="flex-col w-1/6 pr-2 mt-3">
                      <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        adapterLocale={ukLocale}>
                        <DateTimePicker
                          clearable
                          label="Resolution Date"
                          value={resolutionDate}
                          onChange={(newValue) => {
                            setResolutionDate(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField size="medium" {...params} />
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                    <div className="flex-col w-1/6 pr-2 mx-4">
                      <Typography>Extra Info</Typography>
                      <TextField
                        type="text"
                        variant="outlined"
                        value={extraInfo}
                        rows="1"
                        onChange={(event) => changeExtraInfo(event)}
                        className="w-full"></TextField>
                    </div>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={outcomeAsQuestion}
                          onChange={changeOutcomeAsQuestion}
                        />
                      }
                      label="Outcome as a Question"
                    />
                  </div>
                </>
              )
            ) : (
              <div className="break-words">
                <>
                  <div className="flex items-start justify-between mb-1 max-h-42 overflow-y-auto">
                    <Typography sx={{ fontWeight: 600, fontSize: '1.1rem' }}>
                      <span className="whitespace-pre-line">
                        {question.question_text}
                      </span>
                    </Typography>
                    <div className="flex items-center">
                      <div className="">
                        {question.current_crowd_forecast && (
                          <div className="flex items-center mr-2">
                            <MdSpeed className="mr-1" size={22} />
                            <Typography sx={{ mr: 0.6 }}>
                              Crowd Forecast:
                            </Typography>
                            <Typography className="font-semibold"></Typography>
                            {question.current_crowd_forecast && (
                              <Typography>
                                {Number.isInteger(
                                  question.current_crowd_forecast * 100
                                )
                                  ? question.current_crowd_forecast * 100
                                  : (
                                      question.current_crowd_forecast * 100
                                    ).toFixed(2)}
                                %
                              </Typography>
                            )}
                          </div>
                        )}
                      </div>
                      {!hasResolved && (
                        <div>
                          <Typography
                            sx={{
                              borderColor: getStatusColour(),
                              backgroundColor: getStatusColour(),
                              py: 0,
                              px: 0.3,
                              fontSize: '0.9rem'
                            }}
                            className={`border-2 rounded text-white font-normal text-xs px-1 ml-1`}>
                            {question.status !== 'Completed'
                              ? question.status
                              : completedStatusCalc()}
                          </Typography>
                        </div>
                      )}
                      {question.outcome_as_question && (
                        <Typography
                          sx={{
                            borderColor: theme.palette.statuses.mid9,
                            backgroundColor: theme.palette.statuses.mid9,
                            py: 0,
                            px: 0.3,
                            fontSize: '0.85rem',
                            mx: 0.6
                          }}
                          className={`border-2 rounded text-white font-normal text-xs px-1 ml-1`}>
                          Outcome Question
                        </Typography>
                      )}
                      {hasResolved && (
                        <Typography
                          sx={{
                            borderColor: getResolutionColour(),
                            backgroundColor: getResolutionColour(),
                            py: 0,
                            px: 0.3,
                            fontSize: '0.85rem',
                            mx: 0.6,
                            textTransform: 'capitalize'
                          }}
                          className={`border-2 rounded text-white font-normal text-xs px-1 ml-1`}>
                          {question.current_resolution?.name.replace(
                            'Not Set',
                            'Reopened'
                          )}
                        </Typography>
                      )}
                    </div>
                  </div>
                </>
                <Divider />
                <div className="QuestionInfo flex flex-wrap items-center justify-between ml-2">
                  <div className="flex items-center">
                    <Typography
                      sx={{ fontSize: '0.875rem', mr: 0.6 }}
                      color="text.secondary">
                      Posted {timeSincePosted()}
                    </Typography>
                    {(userData.role === 'Admin' ||
                      userData.role === 'Moderator' ||
                      usernameSettings.active) && (
                      <div className="flex items-center">
                        <Typography
                          sx={{ mr: 0.6, fontSize: '0.875rem' }}
                          color="text.secondary">
                          by
                        </Typography>
                        <Typography
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate(
                              `/profile/${question.created_by.username}`
                            );
                          }}
                          tabIndex={0}
                          onKeyDown={(e) => {
                            if (e.key === ' ' || e.key === 'Enter') {
                              e.stopPropagation();
                              navigate(
                                `/profile/${question.created_by.username}`
                              );
                            }
                          }}
                          sx={{ fontSize: '0.875rem' }}
                          className="hover:underline hover:cursor-pointer"
                          color="primary.main">
                          {question.created_by.username}
                        </Typography>
                      </div>
                    )}
                    <div className="pb-1">
                      <RoleBadgeIcon
                        role={usersData[question.created_by.id]?.role}
                      />
                    </div>
                    {isEdited() && (
                      <div className="mr-1">
                        <Tooltip title="Edited">
                          <div>
                            <FaAsterisk className="py-1" />
                          </div>
                        </Tooltip>
                      </div>
                    )}
                    {question.resolvable && (
                      <div className="mr-1">
                        <Tooltip title="Is Resolvable">
                          <div>
                            <FiCheck />
                          </div>
                        </Tooltip>
                      </div>
                    )}
                    {question.relevant && (
                      <div className="mr-1">
                        <Tooltip title="Is Relevant" placement="bottom">
                          <div>
                            <FiCheck />
                          </div>
                        </Tooltip>
                      </div>
                    )}
                    {question.forecast_override === 'True' &&
                      ((outcome.created_by.username === userData.username &&
                        forecastOverruleSettings.options.find(
                          (setting) => setting.name === 'outcome owner'
                        ).selected === true) ||
                        userData.role === 'Admin') && (
                        <div className="mr-1">
                          <Tooltip title="Forecast override" placement="bottom">
                            <div>
                              <FiCheck />
                            </div>
                          </Tooltip>
                        </div>
                      )}
                  </div>
                  <div>
                    {resolution_text && (
                      <Typography
                        sx={{ fontSize: '0.95rem', mr: 0.6 }}
                        color="text.secondary">
                        {resolution_text}
                      </Typography>
                    )}
                  </div>
                </div>

                <div className="markdown flex flex-wrap pt-2 mb-1 ml-2 max-h-64 overflow-y-auto">
                  <div className="flex-col pr-2">
                    <Typography
                      sx={{ fontSize: '1rem', fontWeight: 'bold' }}
                      color="text.secondary">
                      Resolution Criteria
                    </Typography>
                    <Typography sx={{ fontSize: '1rem' }} component="div">
                      <Markdown description={question.resolution_criteria} />
                    </Typography>
                  </div>
                  <div className="flex-col pr-2">
                    <Typography
                      sx={{ fontSize: '1rem', fontWeight: 'bold' }}
                      color="text.secondary">
                      Source Link
                    </Typography>
                    <Typography sx={{ fontSize: '1rem' }}>
                      {question.source}
                    </Typography>
                  </div>
                  <div className="flex-col pr-2">
                    <Typography
                      sx={{ fontSize: '1rem', fontWeight: 'bold' }}
                      color="text.secondary">
                      Resolution Date
                    </Typography>
                    <Typography sx={{ fontSize: '1rem' }}>
                      {typeof question.resolution_date === 'string'
                        ? moment.utc(question.resolution_date).local().format(
                            // displayed for freeform questions
                            'dddd, MMMM Do YYYY'
                          )
                        : (question.resolution_date.start
                            ? moment
                                .utc(question.resolution_date.start)
                                .local()
                                .format(
                                  // displayed for structured questions with date ranges
                                  'dddd, MMMM Do YYYY'
                                ) + ' - '
                            : '') +
                          moment
                            .utc(question.resolution_date.end)
                            .local()
                            .format(
                              // displayed for all structured questions
                              'dddd, MMMM Do YYYY'
                            )}
                    </Typography>
                  </div>
                  {question.extra_info && (
                    <div className="flex-col pr-2">
                      <Typography
                        sx={{ fontSize: '1rem', fontWeight: 'bold' }}
                        color="text.secondary">
                        Extra Info
                      </Typography>
                      <Typography sx={{ fontSize: '1rem' }}>
                        <span className="whitespace-pre-line">
                          {question.extra_info}
                        </span>
                      </Typography>
                    </div>
                  )}
                </div>

                {outcome.forecast_mechanism === 'derived' &&
                  (daysSinceOutcomeForecastingStarted() > 7 ||
                    (!isNaN(question.unique_user_forecasters) &&
                      question.unique_user_forecasters >= 9)) && (
                    <div>
                      {!isNaN(question.total_user_forecasts) && (
                        <div className="flex items-center mr-2">
                          <FiStar className="mr-1 pb-0.5" fill="black" />
                          <Typography sx={{ mr: 0.6 }}>
                            Total User Forecasts:
                          </Typography>
                          <Typography className="font-semibold"></Typography>
                          <Typography>
                            {question.total_user_forecasts}
                          </Typography>
                        </div>
                      )}
                      {!isNaN(question.unique_user_forecasters) && (
                        <div className="flex items-center mr-2">
                          <FiStar className="mr-1 pb-0.5" fill="black" />
                          <Typography sx={{ mr: 0.6 }}>
                            Unique User Forecasters:
                          </Typography>
                          <Typography className="font-semibold"></Typography>
                          <Typography>
                            {question.unique_user_forecasters}
                          </Typography>
                        </div>
                      )}
                    </div>
                  )}

                {forecasts.length > 1 && (
                  <div className="my-2">
                    <ForecastTimeline
                      axisLabels={detail}
                      width={detail ? 'large' : 'small'}
                      title={detail ? 'Forecast Timeline' : undefined}
                      forecasts={forecasts}
                    />
                  </div>
                )}
                {question.forecast_link && (
                  <div className="flex-col ml-2 pr-2">
                    <Link
                      sx={{ fontSize: '1rem', display: 'inline-block' }}
                      underline="hover"
                      target="newtab"
                      href={
                        question.forecast_link.slice(0, 3) === 'http'
                          ? question.forecast_link
                          : 'http://' + question.forecast_link
                      }>
                      <Button
                        variant="outlined"
                        className="flex items-center whitespace-pre-line w-fit">
                        <ShowChart className="mr-1 pl-0.5 pb-0.5" />
                        Track Forecasts
                      </Button>
                    </Link>
                  </div>
                )}
              </div>
            )}
          </div>
          {question.status === 'Duplicate' && (
            <div className="px-5">
              <Button
                className="text-blue-400 text-sm underline"
                onClick={() => openQuestionView(question.parent_question_id)}>
                Duplicate Question
              </Button>
            </div>
          )}
          <div className={`items-center ml-5 ${detail ? '' : 'flex'}`}>
            {Object.hasOwn(question, 'question_score') && (
              <div className="flex items-center mr-2">
                <FiStar className="mr-1 pb-0.5" fill="black" />
                <Typography sx={{ mr: 0.6 }}>
                  {question.question_score_type} Question Score:
                </Typography>
                <Typography
                  sx={{ fontWeight: 500 }}
                  className={`${categoryTextColor(
                    question.question_score_category.name
                  )}`}>
                  {question.question_score_category.name +
                    ' (' +
                    (question.question_score * 100).toFixed(2) +
                    ')'}
                </Typography>
              </div>
            )}
            {currentUserForecast &&
              (currentUserForecast?.probability?.bucket ? (
                <div className="flex items-center mr-2">
                  <ShowChart sx={{ fontSize: '1.1rem', mr: 0.6 }} />
                  <Typography sx={{ mr: 0.6 }}>
                    Your Forecast:{' '}
                    {currentUserForecast.probability.bucket?.name} (
                    {(
                      currentUserForecast?.probability?.display_probability *
                      100
                    ).toFixed(1)}
                    %)
                  </Typography>
                </div>
              ) : (
                <div className="flex items-center mr-2">
                  <ShowChart sx={{ fontSize: '1.1rem', mr: 0.6 }} />
                  <Typography sx={{ mr: 0.6 }}>
                    Your Forecast:{' '}
                    {(
                      currentUserForecast?.probability.display_probability * 100
                    ).toFixed(1)}
                    %
                  </Typography>
                </div>
              ))}
            {currentRelevanceEvaluation !== undefined && (
              <div className="flex items-center mr-2">
                <SsidChartOutlined sx={{ fontSize: '1.1rem', mr: 0.6 }} />
                <Typography sx={{ mr: 0.6 }}>Your Relevance: </Typography>
                <Typography
                  className={`${
                    currentRelevanceEvaluation.relevance_level > 0
                      ? 'text-green-500'
                      : currentRelevanceEvaluation.relevance_level === 0
                      ? 'text-amber-500'
                      : 'text-red-500'
                  }`}>
                  {currentRelevanceEvaluation.relevance_level.toFixed(3)}
                </Typography>
              </div>
            )}

            {Object.hasOwn(question, 'relevance_level') && (
              <div className="flex items-center mr-2">
                <StackedLineChart
                  sx={{ fontSize: '1.1rem', mr: 0.6 }}
                  fill="black"
                />
                <Typography sx={{ mr: 0.6 }}>Crowd Relevance:</Typography>
                <Typography
                  sx={{ fontWeight: 500 }}
                  className={`${
                    question.relevance_level > 0
                      ? 'text-green-500'
                      : question.relevance_level === 0
                      ? 'text-amber-500'
                      : 'text-red-500'
                  }`}>
                  {question.relevance_level.toFixed(3)}
                </Typography>
              </div>
            )}
            {Object.hasOwn(question, 'current_impact') && (
              <div className="flex items-center mr-2">
                <PieChart sx={{ fontSize: '1.1rem', mr: 0.6 }} fill="black" />
                <Typography sx={{ mr: 0.6 }}>Current Impact:</Typography>
                <Typography
                  sx={{ fontWeight: 500 }}
                  className={`${
                    question.current_impact > 0
                      ? 'text-green-500'
                      : 'text-red-500'
                  }`}>
                  {(question.current_impact * 100).toFixed(2)}%
                </Typography>
              </div>
            )}
          </div>
          {!detail && (
            <div className="flex pb-1 pt-1 items-center justify-between">
              <div className="ml-5 flex flex-wrap">
                {evaluation &&
                  evaluation.category &&
                  !outcome.statuses.includes('Forecasting') &&
                  !outcome.statuses.includes('Closed') && (
                    <div className="flex items-center mr-2">
                      <MdSpeed className="mr-1 mb-0.5" />
                      <Typography sx={{ mr: 0.6 }}>
                        Your Evaluation:{' '}
                      </Typography>
                      <Typography
                        className={`${categoryTextColor(
                          evaluation.category.name
                        )}`}>
                        {evaluation.category.name}
                      </Typography>
                    </div>
                  )}
                {evaluation &&
                  evaluation.evaluation_score !== null &&
                  evaluation.evaluation_score !== undefined && (
                    <div className="flex items-center mr-2">
                      <FiStar className="mr-1 pb-0.5" />
                      <Typography sx={{ mr: 0.6 }}>
                        Your {evaluation.evaluation_score_type} Evaluation
                        Score:{' '}
                      </Typography>
                      <Typography
                        className={`${evaluationScoreCategoryTextColor(
                          evaluation.evaluation_score_category.name
                        )}`}>
                        {evaluation.evaluation_score_category.name +
                          ' (' +
                          evaluation.evaluation_score +
                          ')'}
                      </Typography>
                    </div>
                  )}
                {canUserEvaluate && (
                  <div className="flex items-center mr-2">
                    <Button disabled={!isLoggedIn} onClick={clickAddEvaluation}>
                      <MdSpeed className="mr-1 mb-0.5" />
                      Add Evaluation
                    </Button>
                  </div>
                )}
                {userData.role !== 'Observer' && (
                  <div className="flex items-center mr-2">
                    <Button
                      disabled={!isLoggedIn}
                      onClick={replyClick}
                      size="small">
                      <GoComment className="mr-1 pl-0.5" />
                      <span>Add a comment</span>
                    </Button>
                  </div>
                )}
                {userData.role !== 'Observer' &&
                  outcome.statuses.includes('Forecasting') &&
                  !question.outcome_as_question &&
                  outcome.forecast_mechanism === 'derived' &&
                  typeof currentUserForecast !== 'undefined' &&
                  typeof currentUserOutcomeForecast !== 'undefined' && (
                    <div className="flex items-center mr-2">
                      <Button
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowRelevanceEvaluationModal(true);
                        }}>
                        <BsPlusSlashMinus className="mr-1 pl-0.5 pb-0.5" />
                        {`${
                          currentRelevanceEvaluation ? 'Override' : 'Add'
                        } Relevance Evaluation`}
                      </Button>
                    </div>
                  )}
                {question.evaluations.length === 0 &&
                  (outcome.statuses.includes('Generation') ||
                    outcome.statuses.includes('Moderation')) &&
                  !evaluation &&
                  (question.created_by.username === userData.username ||
                    userData.role === 'Admin' ||
                    userData.role === 'Moderator') && (
                    <div className="flex items-center mr-2">
                      <Button
                        onClick={() => {
                          setEditCard(!editCard);
                        }}
                        className={'inline-flex items-center'}
                        size="small">
                        <FiEdit className="mr-1 pb-0.5" />
                        {editCard ? 'Cancel' : 'Edit'}
                      </Button>
                    </div>
                  )}
                {(question.created_by.username === userData.username &&
                  (outcome.statuses.includes('Generation') ||
                    outcome.statuses.includes('Moderation'))) ||
                  (userData.role === 'Admin' && (
                    <div className="flex items-center mr-2">
                      <Button
                        onClick={() => {
                          setShowDeleteConfirm(true);
                        }}
                        className={'inline-flex items-center'}
                        size="small">
                        <FiDelete className="mr-1 pl-0.5 pb-0.5" />
                        Delete
                      </Button>
                    </div>
                  ))}
                {(outcome.statuses.includes('Generation') ||
                  outcome.statuses.includes('Moderation') ||
                  outcome.statuses.includes('Evaluation')) &&
                  (userData.role === 'Moderator' ||
                    userData.role === 'Admin') && (
                    <div className="flex items-center mr-2">
                      <Button
                        onClick={() => {
                          setShowModerationModal(true);
                        }}
                        className={'inline-flex items-center'}
                        size="small">
                        Moderate
                      </Button>
                    </div>
                  )}
                {outcome.enable_AI_forecasting === 'True' &&
                  (outcome.created_by.username === userData.username ||
                    userData.role === 'Moderator' ||
                    userData.role === 'Admin') &&
                  outcome.statuses.includes('Forecasting') &&
                  (question.status === 'Accepted' ||
                    question.status === 'Submitted') &&
                  outcome.forecast_mechanism === 'derived' &&
                  aiForecastSetting?.active && (
                    <div className="flex items-center mr-2">
                      <Button
                        size="small"
                        className={'inline-flex items-center'}
                        onClick={(e) => {
                          createAIForecast(e);
                        }}
                        disabled={isAIForecastButtonDisabled}>
                        <span>Add AI Forecast</span>
                      </Button>
                    </div>
                  )}
              </div>
              <Tooltip title="Permalink" placement="bottom">
                <button
                  className="text-grey-darkest ml-auto mr-5 text-xs rounded inline-flex items-center justify-center"
                  onClick={() => openQuestionView(questionId)}>
                  <FiLink className="mr-1" />
                </button>
              </Tooltip>

              {editCard && (
                <div className="flex justify-end px-4">
                  <Button
                    variant="contained"
                    onClick={
                      isStructured
                        ? () => setSaveClicked(true)
                        : updateQuestionData
                    }>
                    {question.status === 'Rejected' &&
                    question.created_by.username === userData.username
                      ? 'Resubmit'
                      : 'Save'}
                  </Button>
                </div>
              )}
            </div>
          )}

          {detail && (
            <div className="pb-1 pt-1">
              <div className="ml-5">
                {evaluation && evaluation.category && (
                  <div className="flex items-center mr-2">
                    <MdSpeed className="mr-1 mb-0.5" />
                    <Typography sx={{ mr: 0.6 }}>Your Evaluation: </Typography>
                    <Typography
                      className={`${categoryTextColor(
                        evaluation.category.name
                      )}`}>
                      {evaluation.category.name}
                    </Typography>
                  </div>
                )}

                {!question.current_crowd_forecast && (
                  <div className="flex items-center mr-2">
                    <FiStar className="mr-1 pb-0.5" fill="black" />
                    <Typography sx={{ mr: 0.6 }}>Question Forecast:</Typography>
                    <Typography className="font-semibold"></Typography>
                    {question.current_crowd_forecast && (
                      <Typography>
                        {Number.isInteger(question.current_crowd_forecast * 100)
                          ? question.current_crowd_forecast * 100
                          : (question.current_crowd_forecast * 100).toFixed(2)}
                        %
                      </Typography>
                    )}
                  </div>
                )}

                {outcome.forecast_mechanism === 'derived' &&
                  (daysSinceOutcomeForecastingStarted() > 7 ||
                    (!isNaN(question.unique_user_forecasters) &&
                      question.unique_user_forecasters >= 9)) &&
                  !isNaN(question.current_crowd_forecast) && (
                    <div className="flex items-center mr-2">
                      <FiStar className="mr-1 pb-0.5" fill="black" />
                      <Typography sx={{ mr: 0.6 }}>
                        Current Crowd Forecast:{' '}
                        {(question.current_crowd_forecast * 100).toFixed(1)}%
                      </Typography>
                    </div>
                  )}

                {question?.crowd_score && (
                  <div className="flex items-center mr-2">
                    <PiTarget className="mr-1 pb-0.5" fill="black" />
                    <Typography sx={{ mr: 0.6 }}>
                      Your Crowd Forecast Score:{' '}
                      {(question?.crowd_score).toFixed(3)}
                    </Typography>
                  </div>
                )}

                {question?.baseline_score && (
                  <div className="flex items-center mr-2">
                    <PiTargetLight className="mr-1 pb-0.5" fill="black" />
                    <Typography sx={{ mr: 0.6 }}>
                      Your Baseline Forecast Score:{' '}
                      {(question?.baseline_score).toFixed(3)}
                    </Typography>
                  </div>
                )}

                {question?.no_interpolation_score && (
                  <div className="flex items-center mr-2">
                    <PiTargetThin className="mr-1 pb-0.5" fill="black" />
                    <Typography sx={{ mr: 0.6 }}>
                      Your Non-Interpolated Forecast Score:{' '}
                      {(question?.no_interpolation_score).toFixed(3)}
                    </Typography>
                  </div>
                )}

                {evaluation &&
                  evaluation.evaluation_score !== null &&
                  evaluation.evaluation_score !== undefined && (
                    <div className="flex items-center mr-2">
                      <FiStar className="mr-1 pb-0.5" />
                      <Typography sx={{ mr: 0.6 }}>
                        Your {evaluation.evaluation_score_type} Evaluation
                        Score:{' '}
                      </Typography>
                      <Typography
                        className={`${evaluationScoreCategoryTextColor(
                          evaluation.evaluation_score_category.name
                        )}`}>
                        {evaluation.evaluation_score_category.name +
                          ' (' +
                          evaluation.evaluation_score +
                          ')'}
                      </Typography>
                    </div>
                  )}

                {question.average_evaluation !== undefined &&
                  question.average_evaluation !== 0 &&
                  question.average_evaluation_category &&
                  (evaluation ||
                    question.created_by.username === userData.username ||
                    outcome.created_by.username === userData.username ||
                    userData.role === 'Admin' ||
                    userData.role === 'Moderator') && (
                    <div className="flex items-center mr-2">
                      <TbLayoutAlignMiddle className="mr-1" />
                      <Typography sx={{ mr: 0.6 }}>
                        Community Evaluation:{' '}
                      </Typography>
                      <Typography
                        className={`${categoryTextColor(
                          question.average_evaluation_category.name
                        )}`}>
                        {question.average_evaluation_category.name +
                          ' (' +
                          Math.round(question.average_evaluation * 10) / 10 +
                          ')'}
                      </Typography>
                    </div>
                  )}

                {question?.outcome_owner_resolution_impact !== undefined &&
                  resolutionSettings.options.find(
                    (setting) => setting.name === 'outcome owner'
                  ).selected === true && (
                    <div className="flex items-center mr-2">
                      <SsidChartOutlined className="mr-1" />
                      <Typography sx={{ mr: 0.6 }}>
                        Resolution Impact:
                      </Typography>
                      <Typography
                        sx={{ fontWeight: 500 }}
                        className={`${
                          question?.outcome_owner_resolution_impact
                            ? 'text-green-500'
                            : 'text-red-500'
                        }`}>
                        {question?.outcome_owner_resolution_impact
                          ? 'Positive'
                          : 'Negative'}
                      </Typography>
                      <div className="ml-1 pb-1 text-sm">
                        <InfoTooltip
                          text={`${
                            question?.outcome_owner_resolution_impact
                              ? 'Positive Question Resolution Impact implies that if the Question resolves true the Outcome statement will be impacted positively.'
                              : 'Negative Question Resolution Impact implies that if the Question resolves true the Outcome statement will be impacted negatively.'
                          }`}
                        />
                      </div>
                    </div>
                  )}

                <div className="flex flex-wrap pt-2 mb-1 ml-5 max-h-40 overflow-y-auto">
                  {userData.role !== 'Observer' && (
                    <div className="flex items-center mr-2">
                      <Button disabled={!isLoggedIn} onClick={replyClick}>
                        <GoComment className="mr-1 pl-0.5" />
                        <span>Add a comment</span>
                      </Button>
                    </div>
                  )}
                  {userData.role !== 'Observer' &&
                    outcome.statuses.includes('Forecasting') &&
                    !question.outcome_as_question &&
                    outcome.forecast_mechanism === 'derived' &&
                    typeof currentUserForecast !== 'undefined' &&
                    typeof currentUserOutcomeForecast !== 'undefined' && (
                      <div className="flex items-center mr-2">
                        <Button
                          onClick={(e) => {
                            e.stopPropagation();
                            setShowRelevanceEvaluationModal(true);
                          }}>
                          <BsPlusSlashMinus className="mr-1 pl-0.5 pb-0.5" />
                          {`${
                            currentRelevanceEvaluation ? 'Override' : 'Add'
                          } Relevance Evaluation`}
                        </Button>
                      </div>
                    )}

                  {(evaluation ||
                    ((question.status === 'Accepted' ||
                      question.status === 'Submitted' ||
                      question.status === 'Closed') &&
                      outcome.forecast_mechanism === 'manual' &&
                      (userData.role === 'Moderator' ||
                        userData.role === 'Admin'))) && (
                    <div className="flex items-center mr-2">
                      <Button
                        disabled={!isLoggedIn}
                        onClick={() => {
                          setShowAddForecastLink(true);
                        }}>
                        <FiLink className="mr-1 pl-0.5" />
                        <span>
                          {(question.forecast_link ? 'Change ' : 'Add ') +
                            'Forecasting Link'}
                        </span>
                      </Button>
                    </div>
                  )}
                  {question.evaluations.length === 0 &&
                    (outcome.statuses.includes('Generation') ||
                      outcome.statuses.includes('Moderation')) &&
                    !evaluation &&
                    (question.created_by.username === userData.username ||
                      userData.role === 'Admin' ||
                      userData.role === 'Moderator') && (
                      <div className="flex items-center mr-2">
                        <Button
                          onClick={() => {
                            // clears local storage 'draft' when question is submitted
                            localStorage.removeItem(
                              'structuredquestion:' +
                                outcome.id.toString() +
                                userData.username
                            );
                            setEditCard(!editCard);
                          }}
                          className={'inline-flex items-center'}>
                          <FiEdit className="mr-1 pb-0.5" />
                          {editCard ? 'Cancel' : 'Edit'}
                        </Button>
                      </div>
                    )}
                  {(question.created_by.username === userData.username &&
                    outcome.statuses.includes('Generation')) ||
                    (userData.role === 'Admin' && (
                      <div className="flex items-center mr-2">
                        <Button
                          onClick={() => {
                            setShowDeleteConfirm(true);
                          }}
                          className={'inline-flex items-center'}>
                          <FiDelete className="mr-1 pl-0.5 pb-0.5" />
                          Delete
                        </Button>
                      </div>
                    ))}
                  {(outcome.statuses.includes('Generation') ||
                    outcome.statuses.includes('Moderation') ||
                    outcome.statuses.includes('Evaluation')) &&
                    (userData.role === 'Moderator' ||
                      userData.role === 'Admin') && (
                      <div className="flex items-center mr-2">
                        <Button
                          onClick={() => {
                            setShowModerationModal(true);
                          }}
                          className={'inline-flex items-center'}
                          size="small">
                          Moderate
                        </Button>
                      </div>
                    )}
                  {outcome.enable_AI_forecasting === 'True' &&
                    (outcome.created_by.username === userData.username ||
                      userData.role === 'Moderator' ||
                      userData.role === 'Admin') &&
                    outcome.statuses.includes('Forecasting') &&
                    (question.status === 'Accepted' ||
                      question.status === 'Submitted') &&
                    outcome.forecast_mechanism === 'derived' &&
                    aiForecastSetting?.active && (
                      <div className="flex items-center mr-2">
                        <Button
                          size="small"
                          className={'inline-flex items-center'}
                          onClick={(e) => {
                            createAIForecast(e);
                          }}
                          disabled={isAIForecastButtonDisabled}>
                          <span>Add AI Forecast</span>
                        </Button>
                      </div>
                    )}
                  {((outcome.created_by.username === userData.username &&
                    resolutionSettings.options.find(
                      (setting) => setting.name === 'outcome owner'
                    ).selected === true) ||
                    (userData.role === 'Admin' &&
                      resolutionSettings.options.find(
                        (setting) => setting.name === 'admin'
                      ).selected === true) ||
                    (userData.role === 'Moderator' &&
                      resolutionSettings.options.find(
                        (setting) => setting.name === 'moderator'
                      ).selected === true)) && (
                    <div className="flex items-center mr-2">
                      <Button
                        onClick={() => {
                          setResolutionImpactSubmitter('outcome owner');
                          setShowResolutionImpactModal(true);
                        }}
                        className={'inline-flex items-center'}>
                        <BsPlusSlashMinus className="mr-1 pl-0.5 pb-0.5" />
                        {question?.outcome_owner_resolution_impact !== undefined
                          ? 'Update Resolution Impact'
                          : 'Add Resolution Impact'}
                      </Button>
                    </div>
                  )}

                  {(outcome.created_by.username === userData.username ||
                    ['Admin', 'Moderator'].includes(userData.role)) &&
                    (outcome.statuses.includes('Forecasting') ||
                      outcome.statuses.includes('Closed')) && (
                      <div className="flex items-center mr-2">
                        <Button
                          onClick={() => {
                            setShowResolutionModal(true);
                          }}
                          className={'inline-flex items-center'}>
                          <BsCheck2All
                            className="mr-1 pl-0.5 pb-0.5"
                            size={20}
                          />
                          {hasResolved ? 'Edit Resolution' : 'Mark as Resolved'}
                        </Button>
                      </div>
                    )}

                  {canUserEvaluate && (
                    <div className="flex items-center mr-2">
                      <Button
                        disabled={!isLoggedIn}
                        onClick={clickAddEvaluation}>
                        <MdSpeed className="mr-1 mb-0.5" />
                        Add Evaluation
                      </Button>
                    </div>
                  )}
                  {editCard && (
                    <div className="ml-auto px-4">
                      <Button
                        onClick={
                          isStructured
                            ? () => setSaveClicked(true)
                            : updateQuestionData
                        }>
                        {question.status === 'Rejected' &&
                        question.created_by.username === userData.username
                          ? 'Resubmit'
                          : 'Save'}
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {showAddForecastLink && (
            <AddForecastLinkModal
              shown={showAddForecastLink}
              close={() => {
                setShowAddForecastLink(false);
              }}
              afterSubmit={() => {
                setShowAddForecastLink(false);
              }}
              questionId={question.id}
            />
          )}

          {showAddEvaluation && (
            <AddEvaluationModal
              shown={showAddEvaluation}
              close={() => {
                setShowAddEvaluation(false);
              }}
              questionId={questionId}
              outcomeId={outcome.id}
            />
          )}

          {showModerationModal && (
            <ModerationModal
              shown={showModerationModal}
              close={() => {
                setShowModerationModal(false);
              }}
              questionId={questionId}
            />
          )}
          {showResolutionImpactModal && (
            <SetResolutionImpactModal
              shown={showResolutionImpactModal}
              close={() => {
                setShowResolutionImpactModal(false);
              }}
              questionId={questionId}
              submitter={resolutionImpactSubmitter}
            />
          )}

          {showResolutionModal && (
            <SetResolutionModal
              shown={showResolutionModal}
              close={() => {
                setShowResolutionModal(false);
              }}
              parentEntity={question}
            />
          )}

          {showRelevanceEvaluationModal && (
            <SetRelevanceEvaluationModal
              shown={showRelevanceEvaluationModal}
              close={() => {
                setShowRelevanceEvaluationModal(false);
              }}
              questionId={questionId}
              outcomeForecast={
                currentUserOutcomeForecast.probability.display_probability
              }
              questionForecast={
                currentUserForecast.probability.display_probability
              }
              existingEvaluation={
                currentRelevanceEvaluation
                  ? currentRelevanceEvaluation.relevance_forecast * 100
                  : null
              }
            />
          )}

          {showDeleteConfirm && (
            <ConfirmationModal
              shown={showDeleteConfirm}
              close={() => {
                setShowDeleteConfirm(false);
              }}
              confirm={() => {
                setShowDeleteConfirm(false);
                deleteQuestionData();
                window.location.reload();
              }}
              confirmationMessage="Do you really want to delete this question? This process cannot be undone"
            />
          )}
        </Card>
        {showAdminForecasts && (
          <AdminForecastView questionId={questionId}></AdminForecastView>
        )}

        {showAddComment && (
          <CreateCommentCard
            close={() => {
              setShowAddComment(false);
            }}
            afterSubmit={() => {
              setShowAddComment(false);
            }}
            questionId={question.id}
          />
        )}
        {showError && (
          <div>
            <ErrorModal
              shown={showError}
              close={() => {
                setShowError(false);
              }}
              errorMessage={errorMessage}
            />
          </div>
        )}
        {showSuccess && (
          <div>
            <SuccessModal
              shown={showSuccess}
              close={() => {
                setShowSuccess(false);
              }}
              successMessage={successMessage}
            />
          </div>
        )}
      </div>
    );
  } else {
    content = null;
  }
  return <section>{content}</section>;
}
